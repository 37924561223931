import React, { createContext, useContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import defaultIcon from "assets/images/default-avatar.png";

interface UserData {
  uuid: string;
  first_name: string;
  last_name: string;
  email: string;
  role: string;
  avatar: string;
  preferences: {
    modules: string[];
    llm_provider: string;
    llm_model: string;
  };
  // Weitere Felder hier definieren, falls benötigt
}

interface UserContextProps {
  userData: UserData | null;
  setUserData: React.Dispatch<React.SetStateAction<UserData | null>>;
  avatarUrl: string | null;
  setAvatarUrl: React.Dispatch<React.SetStateAction<string | null>>;
  refreshUserData: () => Promise<void>;
  clearUserData: () => void;
}

const UserContext = createContext<UserContextProps | undefined>(undefined);

export const UserProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const [userData, setUserData] = useState<UserData | null>(() => {
    // Hole `userData` aus `sessionStorage`, falls vorhanden
    const storedUserData = sessionStorage.getItem("userData");
    return storedUserData ? JSON.parse(storedUserData) : null;
  });
  const [avatarUrl, setAvatarUrl] = useState<string | null>(() => {
    // Hole avatarUrl aus sessionStorage, falls vorhanden
    return sessionStorage.getItem("avatarUrl");
  });

  const clearUserData = () => {
    // Clear user data from state and sessionStorage
    setUserData(null);
    setAvatarUrl(null);
    sessionStorage.removeItem("userData");
    sessionStorage.removeItem("avatarUrl");
    localStorage.removeItem("authToken");
  };

  const refreshUserData = async () => {
    try {
      const token = await getAccessTokenSilently();
      // Store the token in localStorage for API requests
      localStorage.setItem("authToken", token);

      const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
      const response = await fetch(backend_api_url + "/users/login", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok)
        throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      setUserData(data);

      // Speichere userData in `sessionStorage`
      sessionStorage.setItem("userData", JSON.stringify(data));

      // Use the avatar URL directly from the database
      setAvatarUrl(data.avatar || defaultIcon);
      // Store URL in sessionStorage
      sessionStorage.setItem("avatarUrl", data.avatar || defaultIcon);
      return data;
    } catch (error) {
      console.error("Fehler beim Abrufen von userData:", error);
      throw error;
    }
  };

  useEffect(() => {
    // API-Aufruf nur, wenn Auth0 geladen ist und `isAuthenticated` true ist
    if (!isLoading && isAuthenticated) {
      refreshUserData();
    } else if (!isAuthenticated) {
      clearUserData();
    }
  }, [isLoading, isAuthenticated]);

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
        avatarUrl,
        setAvatarUrl,
        refreshUserData,
        clearUserData,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error(
      "useUserContext muss innerhalb von UserProvider verwendet werden"
    );
  }
  return context;
};
