import React, { useState } from "react";
import SectionWrapper from "components/Common/SectionWrapper";
import FormContainer from "components/Forms/FormContainer";
import FormLabel from "components/Forms/FormLabel";
import FormContent from "components/Forms/FormContent";
import Toggle from "components/Forms/Toggle";
import TextField from "components/Forms/TextField";
import FileUpload from "components/Forms/FileUpload";
import AudioInputField from "components/Forms/AudioInputField";
import { useAuth0 } from "@auth0/auth0-react";
import CustomizedSnackbars from "components/Forms/Snackbar";

interface InputSectionProps {
  onInspirationChange: (value: string) => void;
  inspiration: string;
  setFile: (file_id: string) => void;
  setAudio: (audio_id: string) => void;
  inputType: "text" | "voice";
  setInputType: (input_type: "text" | "voice") => void;
}

const InputSection: React.FC<InputSectionProps> = ({
  onInspirationChange,
  inspiration,
  setFile,
  setAudio,
  inputType,
  setInputType,
}) => {
  const { getAccessTokenSilently } = useAuth0();
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarType, setSnackbarType] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >(undefined);

  const handlePromptInputTypeSelect = (value: string) => {
    setInputType(value as "text" | "voice");
  };

  const handleFileUploaded = (file: File) => {
    console.log(`File uploaded: ${file.name}`);
    upload(file);
  };

  const upload = async (file: File | Blob) => {
    const token = await getAccessTokenSilently();
    const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;

    const formData = new FormData();
    formData.append("file", file);

    const url = new URL(backend_api_url + "/files/upload");
    url.searchParams.append("container_name", "copywritingfileupload");

    try {
      const response = await fetch(url.toString(), {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Upload failed: ${await response.text()}`);
      }

      const file_id = await response.json();
      console.log("Upload successful, file_id:", file_id);

      // Setze je nach Dateityp den entsprechenden State
      if (file instanceof File) {
        setFile(file_id);
      } else if (file.type.startsWith("audio/")) {
        setAudio(file_id);
      }

      return file_id;
    } catch (error) {
      console.error("Upload error:", error);
      throw error;
    }
  };

  const handleRecordingStart = () => {
    console.log("Recording started");
  };

  const handleRecordingStop = (audioBlob: Blob | null) => {
    console.log("Recording stopped, audio blob:", audioBlob);
    if (audioBlob) {
      upload(audioBlob);
      setSnackbarMessage("Audio uploaded successfully");
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("success");
    }
  };

  return (
    <SectionWrapper
      title="Input"
      description="Create new content from existing files or texts."
    >
      <FormContainer className="gap-4">
        <FormLabel
          label="Inspiration"
          description="Topics you want to write about"
          required={true}
        />
        <FormContent>
          <Toggle
            options={[
              { caption: "Text", value: "text" },
              { caption: "Voice", value: "voice" },
            ]}
            onSelect={handlePromptInputTypeSelect}
          />
          {inputType === "text" ? (
            <TextField
              name="prompt"
              ariaLabel="Prompt"
              placeholder="Based on my file about santa clause, write a post about why childrend can't afford to be naughty this year."
              maxChars={20000}
              onChange={onInspirationChange}
              initialValue={inspiration}
              fixedHeight={true}
            />
          ) : (
            <AudioInputField
              onRecordingStart={handleRecordingStart}
              onRecordingStop={handleRecordingStop}
              maxDuration={120} // Set max duration to 2 minutes
            />
          )}
        </FormContent>
      </FormContainer>
      <FormContainer className="gap-4">
        <FormLabel
          label="Additional files"
          description="Upload additional knowledge"
        />
        <FormContent>
          <FileUpload
            allowedFormats={["pdf", "csv", "xls", "xlsx"]}
            formatMessage="PDF, CSV, XLS, XLSX"
            onFileUploaded={handleFileUploaded}
          />
        </FormContent>
      </FormContainer>
      {snackbarMessage && (
        <CustomizedSnackbars
          message={snackbarMessage}
          type={snackbarType}
          key={snackbarKey}
        />
      )}
    </SectionWrapper>
  );
};

export default InputSection;
