import FormLabel from "components/Forms/FormLabel";
import FormContainer from "components/Forms/FormContainer";
import FormContent from "components/Forms/FormContent";
import SectionWrapper from "components/Common/SectionWrapper";
import TextField from "components/Forms/TextField";
import { useEffect, useState } from "react";
import Button from "components/Forms/Button";
import Spinner from "components/Forms/Spinner";
import linkIcon from "assets/images/link-external-01.svg";
import { useAuth0, User } from "@auth0/auth0-react";
import CustomizedSnackbars from "components/Forms/Snackbar";
import Toggle from "components/Forms/Toggle";
import UserForm from "components/Forms/UserForm";

interface Company {
  id: string;
  name: string;
  avatar: string;
  api_keys: { [key: string]: string };
  modules: string[];
  users: string[];
}

const UserSection = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarType, setSnackbarType] = useState<
    "success" | "error" | "warning" | "info" | undefined
  >(undefined);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const onSelect = async (companyId: string) => {
    const company = companies.find((company) => company.id === companyId);
    if (company) {
      setSelectedCompany(company);
    }
  };

  const fetchUsers = async () => {
    if (!selectedCompany?.id) return;

    const accessToken = await getAccessTokenSilently();
    const backendUrl = process.env.REACT_APP_BACKEND_API_URL;
    const response = await fetch(
      `${backendUrl}/companies/users/${selectedCompany.id}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = await response.json();
    setUsers(data);
  };

  const fetchCompanies = async () => {
    const accessToken = await getAccessTokenSilently();
    const backendUrl = process.env.REACT_APP_BACKEND_API_URL;
    const response = await fetch(`${backendUrl}/companies`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    const data = await response.json();
    setCompanies(data);
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (companies.length > 0 && !selectedCompany) {
      onSelect(companies[0].id);
    }
  }, [companies]);

  useEffect(() => {
    if (selectedCompany) {
      fetchUsers();
    }
  }, [selectedCompany]);

  const handleinputchange = (text: string) => {
    setEmail(text);
  };

  const handleSubmit = async () => {
    setLoading(true);
    const accessToken = await getAccessTokenSilently();
    const backendUrl = process.env.REACT_APP_BACKEND_API_URL;
    const url = new URL(
      backendUrl + "/companies/invite/" + selectedCompany?.id
    );
    url.searchParams.set("email", email);
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    });
    setSnackbarMessage("Invite sent successfully");
    setSnackbarType("success");
    setSnackbarKey(snackbarKey + 1);
    setEmail("");
    setLoading(false);
  };

  return (
    <SectionWrapper title="Users" description="Manage users access to Forge.">
      <FormContainer>
        <FormLabel
          label="Selection"
          description="Select a company whose users you want to manage."
        />
        <FormContent>
          <Toggle
            options={companies.map((company) => ({
              caption: company.name,
              value: company.id,
            }))}
            onSelect={onSelect}
          />
        </FormContent>
      </FormContainer>
      <FormContainer>
        <FormLabel label="Users" description="Manage access of these users." />
        <FormContent>
          {users.map((user) => (
            <UserForm key={user.uuid} avatar={user.avatar} user={user} />
          ))}
        </FormContent>
      </FormContainer>
      <FormContainer>
        <FormLabel
          label="Invite new User"
          description="Input the email of the user you want to invite."
        />
        <FormContent>
          <TextField
            placeholder="Email"
            name="email"
            ariaLabel="Email"
            showmaxChars={false}
            onChange={handleinputchange}
          />
        </FormContent>
      </FormContainer>
      <FormContainer>
        <FormLabel
          label="Send Invite"
          description="Send the invite to the user."
        />
        <FormContent>
          <div className="flex flex-wrap gap-8">
            <Button
              variant="primary"
              type="button"
              size="md"
              onClick={handleSubmit}
              disabled={loading}
            >
              {loading ? (
                <Spinner />
              ) : (
                <img src={linkIcon} alt="Invite" className="w-5 h-5" />
              )}
              {loading ? `${"Inviting..."}` : `${"Invite"}`}
            </Button>
          </div>
        </FormContent>
      </FormContainer>
      {snackbarMessage && (
        <CustomizedSnackbars
          message={snackbarMessage}
          type={snackbarType}
          key={snackbarKey}
        />
      )}
    </SectionWrapper>
  );
};

export default UserSection;
