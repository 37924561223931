import Header from "components/Common/Header";
import CompanySection from "components/Admin/CompanySection";
import UserSection from "components/Admin/UserSection";
import { Box, Typography } from "@mui/material";

const AdminPanel: React.FC = () => {
  return (
    <>
      <Header title="Admin Panel" />
      <Box sx={{ p: 2, mb: 2, backgroundColor: "#f5f5f5", borderRadius: 1 }}>
        <Typography variant="body2" color="text.secondary">
          API URL: {process.env.REACT_APP_BACKEND_API_URL}
        </Typography>
      </Box>
      <CompanySection />
      <UserSection />
    </>
  );
};

export default AdminPanel;
