import InputSection from "components/SentimentAnalysis/InputSection";
import Header from "components/Common/Header";
import React, { useState, useRef } from "react";
import Button from "components/Forms/Button";
import generateIcon from "assets/images/star-06-white.svg";
import FormContainer from "components/Forms/FormContainer";
import { useAuth0 } from "@auth0/auth0-react";
import CustomizedSnackbars from "components/Forms/Snackbar";
import ProgressBar from "components/Forms/ProgressBar";
import { convertStatusMessage } from "components/SentimentAnalysis/StatusConverter";
import { useUserContext } from "contexts/UserContext";
import OutputSection from "components/SentimentAnalysis/OutputSection";

const SentimentAnalysisPage: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { userData } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [outputContent, setOutputContent] = useState(() => {
    const storedOutput = sessionStorage.getItem("sentiment_analysis_output");
    return storedOutput ? JSON.parse(storedOutput) : "";
  });

  // State for input values
  const [text, setText] = useState(
    () => sessionStorage.getItem("sentiment_analysis_text") || ""
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarType, setSnackbarType] = useState<
    "success" | "error" | "warning" | "info"
  >("error");
  const [isGenerated, setIsGenerated] = useState(
    () => sessionStorage.getItem("sentiment_analysis_isGenerated") === "true"
  );
  const pageRef = useRef<HTMLDivElement>(null);
  const [statusMessage, setStatusMessage] = useState<string>("");
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [postId, setPostId] = useState<string>(
    () => sessionStorage.getItem("sentiment_analysis_postId") || ""
  );
  const [file, setFile] = useState<string | null>(
    () => sessionStorage.getItem("sentiment_analysis_file") || null
  );
  const [inputType, setInputType] = useState<"text" | "file">(
    () =>
      (sessionStorage.getItem("sentiment_analysis_inputType") as
        | "text"
        | "file") || "text"
  );
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // Polling function to check completion status
  const checkCompletion = async (
    backend_api_url: string,
    token: string,
    postId: string
  ) => {
    const completion_check = await fetch(
      `${backend_api_url}/sentiment_analysis/posts/${postId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (!completion_check.ok) {
      throw new Error("Completion check failed");
    }

    const completion_data = await completion_check.json();

    if (!completion_data || !completion_data.status) {
      throw new Error("Invalid response format");
    }

    const { progress, status } = convertStatusMessage(
      completion_data.status.status,
      userData?.preferences.llm_provider || "AI",
      completion_data.status.status_message
    );
    setProgressPercentage(progress);
    setStatusMessage(status);

    if (completion_data.status.status === "completed") {
      if (!completion_data.output?.history?.length) {
        throw new Error("No output content available");
      }

      const newOutput = {
        text: completion_data.output.history[
          completion_data.output.history.length - 1
        ].text,
        json: JSON.parse(
          completion_data.output.history[
            completion_data.output.history.length - 1
          ].text
        ),
      };

      setOutputContent(newOutput);
      console.log(newOutput);
      sessionStorage.setItem(
        "sentiment_analysis_output",
        JSON.stringify(newOutput)
      );

      setIsGenerated(true);
      sessionStorage.setItem("sentiment_analysis_isGenerated", "true");

      if (completion_data.input.serialized_input?.file) {
        setInputType("text");
        setText(completion_data.input.serialized_input.file);
        sessionStorage.setItem(
          "sentiment_analysis_text",
          completion_data.input.serialized_input.file
        );
      }

      setLoading(false);
      setStatusMessage("");
      setProgressPercentage(0);

      // Scroll to output section
      setTimeout(() => {
        pageRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    } else {
      // Retry after a delay if not completed
      setTimeout(() => checkCompletion(backend_api_url, token, postId), 100);
    }
  };

  const handleGenerate = async () => {
    try {
      setLoading(true);
      setStatusMessage("Initializing...");

      const token = await getAccessTokenSilently();
      const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;

      if (!backend_api_url) {
        console.error("Backend API URL is not defined");
        setLoading(false);
        return;
      }

      let response;
      if (inputType === "text") {
        setFile(null);
        sessionStorage.removeItem("sentiment_analysis_file");
        if (text === "") {
          setErrorMessage("Please enter a text");
          setSnackbarKey((prev) => prev + 1);
          setSnackbarType("error");
          return;
        }
        response = await fetch(`${backend_api_url}/sentiment_analysis/posts`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            text: {
              text: text,
            },
          }),
        });
      } else {
        if (file === null) {
          setErrorMessage("Please upload a file");
          setSnackbarKey((prev) => prev + 1);
          setSnackbarType("error");
          setLoading(false);
          return;
        }
        response = await fetch(`${backend_api_url}/sentiment_analysis/posts`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            text: {
              text: "",
            },
            file_id: file,
          }),
        });
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "An error occurred");
      }

      const postId = await response.json();
      setPostId(postId);
      sessionStorage.setItem("sentiment_analysis_postId", postId);

      // Start polling
      checkCompletion(backend_api_url, token, postId);
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("An error occurred while generating content");
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
      setStatusMessage("Error occurred");
      setLoading(false);
    }
  };

  // Handlers for input changes
  const handleTextChange = (value: string) => {
    setText(value);
    sessionStorage.setItem("sentiment_analysis_text", value);
  };

  // Create wrapper functions to handle sessionStorage
  const handleFileChange = (fileId: string | null) => {
    setFile(fileId);
    if (fileId) {
      sessionStorage.setItem("sentiment_analysis_file", fileId);
    } else {
      sessionStorage.removeItem("sentiment_analysis_file");
    }
  };

  return (
    <div ref={pageRef}>
      <Header title="Sentiment Analysis" />
      <InputSection
        onTextChange={handleTextChange}
        setFile={handleFileChange}
        text={text}
        inputType={inputType}
        setInputType={setInputType}
        textareaRef={textareaRef}
      />
      <FormContainer className="mt-8 justify-center">
        {loading ? (
          <ProgressBar
            progress={progressPercentage}
            statusMessage={statusMessage}
          />
        ) : (
          <Button
            variant="primary"
            type="button"
            size="md"
            onClick={handleGenerate}
            disabled={loading}
          >
            <img src={generateIcon} alt="Analyze" className="w-5 h-5" />
            Analyze
          </Button>
        )}
      </FormContainer>
      {!loading && isGenerated && (
        <div>
          <OutputSection
            output={outputContent.text}
            textareaRef={textareaRef}
            pageRef={pageRef}
          />
        </div>
      )}
      {errorMessage && (
        <CustomizedSnackbars
          message={errorMessage}
          type={snackbarType}
          key={snackbarKey}
        />
      )}
    </div>
  );
};

export default SentimentAnalysisPage;
