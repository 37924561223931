import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { Modal, Image as AntImage } from "antd";
import Header from "components/Common/Header";
import { useUserContext } from "contexts/UserContext";

// Import custom components
import SectionWrapper from "components/Common/SectionWrapper";
import FormContainer from "components/Forms/FormContainer";
import FormContent from "components/Forms/FormContent";
import FormLabel from "components/Forms/FormLabel";
import Button from "components/Forms/Button";
import Toggle from "components/Forms/Toggle";
import ToggleSwitch from "components/Forms/ToggleSwitch";
import ProgressBar from "components/Forms/ProgressBar";
import CustomizedSnackbars from "components/Forms/Snackbar";

const FluxHistoryPage: React.FC = () => {
  const {
    isAuthenticated,
    isLoading: authLoading,
    getAccessTokenSilently,
  } = useAuth0();
  const navigate = useNavigate();
  const { userData } = useUserContext();

  const [userImages, setUserImages] = useState<any[]>([]);
  const [sharedImages, setSharedImages] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState("my-images");
  const [isLoading, setIsLoading] = useState(true);
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarType, setSnackbarType] = useState<
    "success" | "error" | "warning" | "info"
  >("success");

  // Fetch actual data from the API
  const fetchUserImages = async () => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;

      if (!backend_api_url) {
        throw new Error("Backend API URL is not defined");
      }

      // Fetch user images
      const userImagesResponse = await fetch(`${backend_api_url}/flux/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!userImagesResponse.ok) {
        const errorData = await userImagesResponse.json();
        throw new Error(errorData.detail || "Failed to fetch user images");
      }

      const userImagesData = await userImagesResponse.json();
      setUserImages(userImagesData);

      // Fetch shared images
      const sharedImagesResponse = await fetch(
        `${backend_api_url}/flux/shared`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!sharedImagesResponse.ok) {
        const errorData = await sharedImagesResponse.json();
        throw new Error(errorData.detail || "Failed to fetch shared images");
      }

      const sharedImagesData = await sharedImagesResponse.json();
      setSharedImages(sharedImagesData);

      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching images:", error);
      setErrorMessage(
        error instanceof Error ? error.message : "Failed to fetch images"
      );
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchUserImages();
    }
  }, [isAuthenticated]);

  const handleUpdateSharing = async (imageId: string, shared: boolean) => {
    try {
      const token = await getAccessTokenSilently();
      const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;

      if (!backend_api_url) {
        throw new Error("Backend API URL is not defined");
      }

      const response = await fetch(`${backend_api_url}/flux/${imageId}/share`, {
        method: "PATCH",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          shared: shared,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to update sharing status");
      }

      const updatedImage = await response.json();

      // Update userImages with the updated image
      setUserImages(
        userImages.map((img) => (img.uuid === imageId ? updatedImage : img))
      );

      // Update shared images as needed
      if (shared) {
        // Add to shared images if not already there
        if (!sharedImages.some((img) => img.uuid === imageId)) {
          setSharedImages([...sharedImages, updatedImage]);
        }
      } else {
        // Remove from shared images
        setSharedImages(sharedImages.filter((img) => img.uuid !== imageId));
      }

      setErrorMessage(
        shared ? "Image is now shared with your team" : "Image is now private"
      );
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("success");
    } catch (error) {
      console.error("Error updating sharing status:", error);
      setErrorMessage(
        error instanceof Error
          ? error.message
          : "Failed to update sharing status"
      );
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
    }
  };

  const handleDeleteImage = async (imageId: string) => {
    try {
      const token = await getAccessTokenSilently();
      const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;

      if (!backend_api_url) {
        throw new Error("Backend API URL is not defined");
      }

      const response = await fetch(`${backend_api_url}/flux/${imageId}`, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to delete image");
      }

      // Update states after successful deletion
      setUserImages(userImages.filter((img) => img.uuid !== imageId));
      setSharedImages(sharedImages.filter((img) => img.uuid !== imageId));

      setErrorMessage("Image deleted successfully");
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("success");
    } catch (error) {
      console.error("Error deleting image:", error);
      setErrorMessage(
        error instanceof Error ? error.message : "Failed to delete image"
      );
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
    }
  };

  const handleShowImageDetails = (image: any) => {
    setSelectedImage(image);
    setIsModalVisible(true);
  };

  if (authLoading || isLoading) {
    return (
      <>
        <Header title="Image History" />
        <div className="flex justify-center items-center h-[80vh]">
          <ProgressBar progress={50} statusMessage="Loading..." />
        </div>
      </>
    );
  }

  if (!isAuthenticated) {
    navigate("/login");
    return null;
  }

  const renderImageGrid = (images: any[]) => {
    if (images.length === 0) {
      return (
        <div className="w-full text-center py-8">
          <p className="text-gray-500">No images found</p>
        </div>
      );
    }

    return (
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mt-4">
        {images.map((image) => (
          <div
            key={image.uuid}
            className="flex flex-col bg-white rounded-lg border border-gray-200 shadow-sm overflow-hidden"
          >
            <div
              className="cursor-pointer h-48 overflow-hidden"
              onClick={() => handleShowImageDetails(image)}
            >
              <img
                src={image.image_url}
                alt={image.original_prompt}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="p-4">
              <div className="text-xs text-gray-500 mb-1">
                {new Date(image.created_at).toLocaleDateString()}
              </div>
              <div className="text-sm font-medium text-gray-800 truncate mb-3">
                {image.original_prompt}
              </div>
              <div className="flex justify-between items-center">
                <Button
                  variant="secondary"
                  type="button"
                  size="sm"
                  onClick={() => handleShowImageDetails(image)}
                >
                  View
                </Button>
                {image.user_id === userData?.uuid && (
                  <>
                    <Button
                      variant="secondary"
                      type="button"
                      size="sm"
                      onClick={() => handleDeleteImage(image.uuid)}
                      className="text-red-600"
                    >
                      Delete
                    </Button>
                    <ToggleSwitch
                      id={`share-${image.uuid}`}
                      onToggle={(checked) =>
                        handleUpdateSharing(image.uuid, checked)
                      }
                      initialState={image.shared}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <>
      <Header title="Image History" />
      <div className="p-6">
        <SectionWrapper
          title="Image History"
          description="Browse your generated images and share them with your team"
        >
          <FormContainer className="w-full justify-between items-center mb-4">
            <div>
              <Button
                variant="primary"
                type="button"
                size="md"
                onClick={() => navigate("/flux")}
              >
                Generate New Image
              </Button>
            </div>
          </FormContainer>

          <FormContainer className="w-full justify-center mb-6">
            <Toggle
              options={[
                { caption: "My Images", value: "my-images" },
                { caption: "Team Images", value: "shared-images" },
              ]}
              onSelect={setActiveTab}
              selected={activeTab === "my-images" ? 0 : 1}
            />
          </FormContainer>

          <FormContainer className="w-full">
            {activeTab === "my-images"
              ? renderImageGrid(userImages)
              : renderImageGrid(sharedImages)}
          </FormContainer>
        </SectionWrapper>

        <Modal
          title="Image Details"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={[
            <Button
              key="close"
              variant="secondary"
              type="button"
              size="md"
              onClick={() => setIsModalVisible(false)}
            >
              Close
            </Button>,
            <Button
              key="open"
              variant="primary"
              type="button"
              size="md"
              onClick={() => window.open(selectedImage?.image_url, "_blank")}
            >
              Open Full Size
            </Button>,
          ]}
          width={800}
        >
          {selectedImage && (
            <div>
              <AntImage
                src={selectedImage.image_url}
                alt={selectedImage.original_prompt}
                style={{ width: "100%" }}
              />
              <div className="mt-4">
                <h4 className="text-lg font-medium mb-2">Original Prompt</h4>
                <p className="text-gray-700 mb-4">
                  {selectedImage.original_prompt}
                </p>

                {selectedImage.enhanced_prompt !==
                  selectedImage.original_prompt && (
                  <>
                    <h4 className="text-lg font-medium mb-2">
                      Enhanced Prompt
                    </h4>
                    <p className="text-gray-700 mb-4">
                      {selectedImage.enhanced_prompt}
                    </p>
                  </>
                )}

                <p className="mt-2 font-medium">
                  <span className="font-bold">Aspect Ratio:</span>{" "}
                  {selectedImage.aspect_ratio || "1:1"}
                </p>

                <div className="mt-4 text-xs text-gray-500">
                  Created: {new Date(selectedImage.created_at).toLocaleString()}
                </div>
              </div>
            </div>
          )}
        </Modal>
      </div>

      {errorMessage && (
        <CustomizedSnackbars
          message={errorMessage}
          type={snackbarType}
          key={snackbarKey}
        />
      )}
    </>
  );
};

export default FluxHistoryPage;
