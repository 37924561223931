import React from "react";
import { useNavigate } from "react-router-dom";
import Header from "components/Common/Header";
import ContentCreationSection from "components/Home/ContentCreationSection";
import { InspirationCardProps } from "components/Home/InspirationCard";
import InspirationSection from "components/Home/InspirationSection";

import inspirationImage01 from "assets/images/inspiration/01.png";
import inspirationImage02 from "assets/images/inspiration/02.png";
import inspirationImage03 from "assets/images/inspiration/03.png";
import inspirationImage04 from "assets/images/inspiration/04.png";
import inspirationImage05 from "assets/images/inspiration/05.png";
import inspirationImage06 from "assets/images/inspiration/06.png";
import inspirationImage07 from "assets/images/inspiration/07.png";
import { useUserContext } from "contexts/UserContext";

const HomePage: React.FC = () => {
  const navigate = useNavigate();
  const { userData, avatarUrl } = useUserContext();
  const modules = userData?.preferences?.modules;

  React.useEffect(() => {
    if (avatarUrl !== undefined) {
      if (userData?.first_name === null || userData?.last_name === null) {
        navigate("/settings");
      }
    }
  }, [userData, navigate]);

  if (
    userData?.first_name === undefined ||
    userData?.last_name === undefined ||
    avatarUrl === undefined
  ) {
    console.log("Some of the user data is undefined");
    console.log("userData", userData);
    console.log("avatarUrl", avatarUrl);
    // refresh the page
    return <div>Loading...</div>;
  }

  const handleCreatePost = () => {
    navigate("/copywriting");
  };

  const handleFindTopics = () => {
    navigate("/research");
  };

  const handleCardAction = (title: string) => {
    console.log(`Clicked on card: ${title}`);
    if (title === "Copywriting") {
      navigate("/copywriting");
    } else if (title === "LMG") {
      navigate("/lmg");
    }
  };

  const inspirationCards: InspirationCardProps[] = [
    {
      image: inspirationImage01,
      title: "Create On-Brand Marketing Texts",
      description:
        "Easily create marketing texts that match your tone of voice and your companies style guides",
      tags: ["Marketing", "Communication"],
      action: () => handleCardAction("Copywriting"),
      module: "COPYWRITING",
    },
    {
      image: inspirationImage02,
      title: "Cold Outreach on LinkedIn",
      description:
        "Start with a LinkedIn profile url and generate a highly personalised cold outreach message",
      tags: ["Marketing", "LinkedIn"],
      action: () => handleCardAction("LMG"),
      module: "LMG",
    },
    {
      image: inspirationImage03,
      title: "Create Blog Articles",
      description:
        "Transform content into professionally formatted blog articles for Webflow publishing",
      tags: ["Content", "Webflow"],
      action: () => navigate("/webflow"),
      module: "WEBFLOW",
    },
    {
      image: inspirationImage04,
      title: "Generate Images",
      description:
        "Create custom AI-generated images for your content and marketing materials",
      tags: ["Design", "Content"],
      action: () => navigate("/flux"),
      module: "FLUX",
    },
    {
      image: inspirationImage05,
      title: "Analyze Sentiment",
      description:
        "Understand the emotional tone and sentiment of text content or documents",
      tags: ["Analysis", "Content"],
      action: () => navigate("/sentiment-analysis"),
      module: "SENTIMENT_ANALYSIS",
    },
    {
      image: inspirationImage07,
      title: "Create News Articles",
      description: "Create news articles from a short messages",
      tags: ["News", "Communication"],
      action: () => handleCardAction("Copywriting"),
      module: "COPYWRITING",
    },
  ];

  const filteredCards = inspirationCards.filter((card) =>
    modules?.includes(card.module)
  );

  return (
    <>
      <Header title="Home" />
      {/* <ContentCreationSection
        onCreatePost={handleCreatePost}
        onFindTopics={handleFindTopics}
      /> */}
      <InspirationSection cards={filteredCards} />
    </>
  );
};

export default HomePage;
