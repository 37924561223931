import InputSection from "components/Copywriting/InputSection";
import Header from "components/Common/Header";
import React, { useState, useRef } from "react";
import SettingsSection from "components/Copywriting/SettingsSection";
import Button from "components/Forms/Button";
import generateIcon from "assets/images/star-06-white.svg";
import FormContainer from "components/Forms/FormContainer";
import OutputSection from "components/Sections/OutputSection";
import { useAuth0 } from "@auth0/auth0-react";
import { DropdownItem } from "components/Forms/DropDown";
import linkedinIcon from "assets/images/linkedin-icon.svg";
import webflowIcon from "assets/images/edit-04.svg";
import CustomizedSnackbars from "components/Forms/Snackbar";
import ProgressBar from "components/Forms/ProgressBar";
import { convertStatusMessage } from "components/Copywriting/StatusConverter";
import { useUserContext } from "contexts/UserContext";
import { useNavigate } from "react-router-dom";

const CopywritingPage: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { userData } = useUserContext();
  const [loading, setLoading] = useState(false);
  const [outputContent, setOutputContent] = useState(() => {
    const saved = sessionStorage.getItem("copywriting_output");
    return saved
      ? JSON.parse(saved)
      : {
          text: "",
          html: "<p>Generate content and you shall find the output here.</p>",
        };
  });

  // State for input values
  const [inspiration, setInspiration] = useState(
    () => sessionStorage.getItem("copywriting_inspiration") || ""
  );
  const [enhancedSearch, setEnhancedSearch] = useState(
    () => sessionStorage.getItem("copywriting_enhancedSearch") === "true"
  );
  const [selectedPlatform, setSelectedPlatform] = useState<string>(
    () => sessionStorage.getItem("copywriting_platform") || "linkedin"
  );
  const [selectedTemplate, setSelectedTemplate] = useState<string>(
    () =>
      sessionStorage.getItem("copywriting_template") || "COPYWRITING_default"
  );
  const [selectedTaskType, setSelectedTaskType] = useState<string>(
    () => sessionStorage.getItem("copywriting_taskType") || "social-media"
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarType, setSnackbarType] = useState<
    "success" | "error" | "warning" | "info"
  >("error");
  const [isGenerated, setIsGenerated] = useState(
    () => sessionStorage.getItem("copywriting_isGenerated") === "true"
  );
  const outputRef = useRef<HTMLDivElement>(null);
  const [statusMessage, setStatusMessage] = useState<string>("");
  const [progressPercentage, setProgressPercentage] = useState<number>(0);
  const [postId, setPostId] = useState<string>(
    () => sessionStorage.getItem("copywriting_postId") || ""
  );
  const [file, setFile] = useState<string | null>(
    () => sessionStorage.getItem("copywriting_file") || null
  );
  const [audio, setAudio] = useState<string | null>(
    () => sessionStorage.getItem("copywriting_audio") || null
  );
  const [inputType, setInputType] = useState<"text" | "voice">(
    () =>
      (sessionStorage.getItem("copywriting_inputType") as "text" | "voice") ||
      "text"
  );
  const navigate = useNavigate();

  // Polling function to check completion status
  const checkCompletion = async (
    backend_api_url: string,
    token: string,
    postId: string
  ) => {
    const completion_check = await fetch(
      `${backend_api_url}/copywriter/posts/${postId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }
    );
    if (!completion_check.ok) {
      throw new Error("Completion check failed");
    }

    const completion_data = await completion_check.json();

    if (!completion_data || !completion_data.status) {
      throw new Error("Invalid response format");
    }

    const { progress, status } = convertStatusMessage(
      completion_data.status.status,
      userData?.preferences.llm_provider || "AI",
      completion_data.status.status_message
    );
    setProgressPercentage(progress);
    setStatusMessage(status);

    if (completion_data.status.status === "completed") {
      if (!completion_data.output?.history?.length) {
        throw new Error("No output content available");
      }

      const newOutput = {
        text: completion_data.output.history[
          completion_data.output.history.length - 1
        ].text,
        html: `<p>${completion_data.output.history[completion_data.output.history.length - 1].text}</p>`,
      };

      setOutputContent(newOutput);
      sessionStorage.setItem("copywriting_output", JSON.stringify(newOutput));

      setIsGenerated(true);
      sessionStorage.setItem("copywriting_isGenerated", "true");

      setLoading(false);
      setStatusMessage("");
      setProgressPercentage(0);

      // Scroll to output section
      setTimeout(() => {
        outputRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 100);
    } else {
      // Retry after a delay if not completed
      setTimeout(() => checkCompletion(backend_api_url, token, postId), 100);
    }
  };

  const handleGenerate = async () => {
    if (inspiration === "" && audio === null) {
      setErrorMessage("Please enter an inspiration");
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
      return;
    }
    try {
      setLoading(true);
      setStatusMessage("Initializing...");

      const token = await getAccessTokenSilently();
      const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;

      if (!backend_api_url) {
        console.error("Backend API URL is not defined");
        setLoading(false);
        return;
      }

      let response;
      if (inputType === "text") {
        response = await fetch(`${backend_api_url}/copywriter/posts`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            inspiration: {
              text: inspiration,
            },
            file_id: file,
            enhanced_search_enabled: enhancedSearch,
            target_platform: selectedPlatform,
            style_template_id: selectedTemplate,
          }),
        });
      } else {
        response = await fetch(`${backend_api_url}/copywriter/posts`, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            inspiration: {
              audio_id: audio,
            },
            file_id: file,
            enhanced_search_enabled: enhancedSearch,
            target_platform: selectedPlatform,
            style_template_id: selectedTemplate,
          }),
        });
      }

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "An error occurred");
      }

      const postId = await response.json();
      setPostId(postId);
      sessionStorage.setItem("copywriting_postId", postId);

      // Start polling
      checkCompletion(backend_api_url, token, postId);
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("An error occurred while generating content");
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
      setStatusMessage("Error occurred");
      setLoading(false);
    }
  };

  // Handlers for input changes
  const handleInspirationChange = (value: string) => {
    setInspiration(value);
    sessionStorage.setItem("copywriting_inspiration", value);
  };

  const handleEnhancedSearchToggle = (value: boolean) => {
    setEnhancedSearch(value);
    sessionStorage.setItem("copywriting_enhancedSearch", value.toString());
  };

  const handlePlatformSelect = (item: DropdownItem) => {
    setSelectedPlatform(item.value);
    sessionStorage.setItem("copywriting_platform", item.value);
  };

  const handleStyleSelect = (item: DropdownItem) => {
    setSelectedTemplate(item.value);
    sessionStorage.setItem("copywriting_template", item.value);
  };

  const handleTaskTypeSelect = (value: string) => {
    setSelectedTaskType(value);
    sessionStorage.setItem("copywriting_taskType", value);
  };

  const handleShare = () => {
    setErrorMessage("Not implemented yet");
    setSnackbarKey((prev) => prev + 1);
    setSnackbarType("error");
  };

  const handleCopyToWebflow = () => {
    // Store the current content in session storage to be accessed by the WebflowPage
    sessionStorage.setItem("webflow_content", outputContent.text);
    // Navigate to the Webflow page
    navigate("/webflow");
  };

  const handleRePrompt = async (updateContent: string) => {
    if (updateContent === "") {
      setErrorMessage("Please enter a reprompt");
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
      return;
    }
    if (postId === "") {
      setErrorMessage("Please generate content first");
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
      return;
    }
    try {
      setLoading(true);
      setStatusMessage("Initializing...");

      const token = await getAccessTokenSilently();
      const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;

      if (!backend_api_url) {
        console.error("Backend API URL is not defined");
        setLoading(false);
        return;
      }

      const response = await fetch(
        `${backend_api_url}/copywriter/posts/${postId}`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            prompt: updateContent,
          }),
        }
      );
      checkCompletion(backend_api_url, token, postId);
    } catch (error) {
      console.error("Error:", error);
      setErrorMessage("An error occurred while reprompting");
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
      setStatusMessage("Error occurred");
      setLoading(false);
    }
  };

  // Create wrapper functions to handle sessionStorage
  const handleFileChange = (fileId: string | null) => {
    setFile(fileId);
    if (fileId) {
      sessionStorage.setItem("copywriting_file", fileId);
    } else {
      sessionStorage.removeItem("copywriting_file");
    }
  };

  const handleAudioChange = (audioId: string | null) => {
    setAudio(audioId);
    if (audioId) {
      sessionStorage.setItem("copywriting_audio", audioId);
    } else {
      sessionStorage.removeItem("copywriting_audio");
    }
  };

  const handleInputTypeChange = (type: "text" | "voice") => {
    setInputType(type);
    sessionStorage.setItem("copywriting_inputType", type);
  };

  return (
    <>
      <Header title="Copywriting" />
      <InputSection
        onInspirationChange={handleInspirationChange}
        setFile={handleFileChange}
        setAudio={handleAudioChange}
        inputType={inputType}
        setInputType={handleInputTypeChange}
        inspiration={inspiration}
      />
      <SettingsSection
        onTaskTypeSelect={handleTaskTypeSelect}
        onPlatformSelect={handlePlatformSelect}
        onEnhancedSearchToggle={handleEnhancedSearchToggle}
        onStyleSelect={handleStyleSelect}
        selectedPlatform={selectedPlatform}
        selectedTemplate={selectedTemplate}
      />
      <FormContainer className="mt-8 justify-center">
        {loading ? (
          <ProgressBar
            progress={progressPercentage}
            statusMessage={statusMessage}
          />
        ) : (
          <Button
            variant="primary"
            type="button"
            size="md"
            onClick={handleGenerate}
            disabled={loading}
          >
            <img src={generateIcon} alt="Generate" className="w-5 h-5" />
            Generate
          </Button>
        )}
      </FormContainer>
      {!loading && isGenerated && (
        <div ref={outputRef}>
          <OutputSection
            outputContent={outputContent}
            setOutputContent={setOutputContent}
            isGenerated={isGenerated}
            handleRePrompt={handleRePrompt}
            shareOptions={[
              //{
              //  icon: linkedinIcon,
              //  label: "Share on Linkedin",
              //  onClick: handleShare
              //}
              {
                icon: webflowIcon,
                label: "Copy to Webflow",
                onClick: handleCopyToWebflow,
              },
            ]}
            title="Copywriting Output"
            description="Edit your copy before sharing it."
          />
        </div>
      )}
      {errorMessage && (
        <CustomizedSnackbars
          message={errorMessage}
          type={snackbarType}
          key={snackbarKey}
        />
      )}
    </>
  );
};

export default CopywritingPage;
