import React, { useState } from "react";
import SectionWrapper from "components/Common/SectionWrapper";
import FormContainer from "components/Forms/FormContainer";
import FormLabel from "components/Forms/FormLabel";
import FormContent from "components/Forms/FormContent";
import PromptEditor from "components/Forms/PromptEditor";
import Button from "components/Forms/Button";
import TextField from "components/Forms/TextField";
import Spinner from "components/Forms/Spinner";
import saveIcon from "assets/images/save.svg";
import deleteIcon from "assets/images/delete.svg";
import { useAuth0 } from "@auth0/auth0-react";
import DropDown from "components/Forms/DropDown";
import { DropdownItem } from "components/Forms/DropDown";
import Toggle from "components/Forms/Toggle";
import ToggleSwitch from "components/Forms/ToggleSwitch";
import CustomizedSnackbars from "components/Forms/Snackbar";

interface CreateSectionProps {
  dropdownItems: DropdownItem[];
  fetchDropdownItems: () => void;
  tool: string;
}

interface PromptData {
  id?: string;
  name: string;
  content: string;
  shared?: boolean;
  user_id?: string;
}

const CreateSection: React.FC<CreateSectionProps> = ({
  dropdownItems,
  fetchDropdownItems,
  tool,
}) => {
  const { getAccessTokenSilently, user } = useAuth0();
  const [editorType, setEditorType] = useState<"create" | "edit" | "delete">(
    () =>
      (sessionStorage.getItem("promptEditorType") as
        | "create"
        | "edit"
        | "delete") || "create"
  );
  const [createPromptData, setCreatePromptData] = useState<PromptData>(() => {
    const saved = sessionStorage.getItem("createPromptData");
    return saved ? JSON.parse(saved) : { name: "", content: "", shared: false };
  });
  const [editPromptData, setEditPromptData] = useState<PromptData>(() => {
    const saved = sessionStorage.getItem("editPromptData");
    return saved ? JSON.parse(saved) : { name: "", content: "", shared: false };
  });
  const [deletePromptData, setDeletePromptData] = useState<PromptData>(() => {
    const saved = sessionStorage.getItem("deletePromptData");
    return saved ? JSON.parse(saved) : { name: "", content: "", shared: false };
  });
  const [loading, setLoading] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarType, setSnackbarType] = useState<
    "success" | "error" | "warning" | "info"
  >("success");
  const [sharedToggle, setSharedToggle] = useState<boolean>(false);
  const [isOwnedByUser, setIsOwnedByUser] = useState<boolean>(true);

  React.useEffect(() => {
    sessionStorage.setItem("promptEditorType", editorType);
    sessionStorage.setItem(
      "createPromptData",
      JSON.stringify(createPromptData)
    );
    sessionStorage.setItem("editPromptData", JSON.stringify(editPromptData));
    sessionStorage.setItem(
      "deletePromptData",
      JSON.stringify(deletePromptData)
    );
  }, [editorType, createPromptData, editPromptData, deletePromptData]);

  const clearStorageAfterOperation = () => {
    setActivePromptData({ name: "", content: "", shared: false });
    sessionStorage.removeItem(`${editorType}PromptData`);
  };

  const handleEditorTypeSelect = (value: string) => {
    const newType = value as "create" | "edit" | "delete";
    setEditorType(newType);

    // Update shared toggle based on the active prompt data for this type
    const newActiveData = {
      create: createPromptData,
      edit: editPromptData,
      delete: deletePromptData,
    }[newType];

    setSharedToggle(newActiveData.shared || false);
  };

  const activePromptData = {
    create: createPromptData,
    edit: editPromptData,
    delete: deletePromptData,
  }[editorType];

  const setActivePromptData = {
    create: setCreatePromptData,
    edit: setEditPromptData,
    delete: setDeletePromptData,
  }[editorType];

  const handlePromptSelect = (item: DropdownItem) => {
    // Check if the prompt is owned by the current user
    const isOwned = !item.user_id || item.user_id === user?.sub;
    setIsOwnedByUser(isOwned);

    setActivePromptData({
      id: item.value,
      name: item.label,
      content: item.content || "",
      shared: item.shared || false,
      user_id: item.user_id,
    });
    setSharedToggle(item.shared || false);
  };

  const handlePromptChange = (content: string) => {
    setActivePromptData((prev) => ({ ...prev, content }));
  };

  const handlePromptNameChange = (name: string) => {
    setActivePromptData((prev) => ({ ...prev, name }));
  };

  const handleSharedToggle = (isShared: boolean) => {
    setSharedToggle(isShared);
    setActivePromptData((prev) => ({ ...prev, shared: isShared }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    const backendApiUrl = process.env.REACT_APP_BACKEND_API_URL;
    if (!backendApiUrl) {
      console.error("Backend API URL is not defined");
      return;
    }
    const token = await getAccessTokenSilently();

    if (editorType === "delete") {
      if (!activePromptData.id) {
        setSnackbarMessage("Please select a prompt to delete");
        setSnackbarKey((prev) => prev + 1);
        setSnackbarType("error");
        setLoading(false);
        return;
      }
      const url = new URL(backendApiUrl + "/templates/delete_template");
      url.searchParams.append("template_id", activePromptData.id || "");
      const response = await fetch(url.toString(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Response from backend:", data);
        fetchDropdownItems();
        setSnackbarMessage("Deleted prompt");
        setSnackbarKey((prev) => prev + 1);
        setSnackbarType("success");
        clearStorageAfterOperation();

        if (editPromptData.id === activePromptData.id) {
          setEditPromptData({ name: "", content: "", shared: false });
          sessionStorage.removeItem("editPromptData");
        }
      } else {
        console.error("Error calling backend:", response.statusText);
      }
    } else if (editorType === "create") {
      if (activePromptData.name === "" || activePromptData.content === "") {
        setSnackbarMessage("Please enter a name and prompt");
        setSnackbarKey((prev) => prev + 1);
        setSnackbarType("error");
        setLoading(false);
        return;
      }
      const url = new URL(backendApiUrl + "/templates/add_template");
      url.searchParams.append("tool", tool);
      const response = await fetch(url.toString(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          template: activePromptData.content,
          name: activePromptData.name,
          shared: activePromptData.shared,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Response from backend:", data);
        fetchDropdownItems();
        setSnackbarMessage("Saved prompt");
        setSnackbarType("success");
        setSnackbarKey((prev) => prev + 1);
        clearStorageAfterOperation();
      } else {
        console.error("Error calling backend:", response.statusText);
      }
    } else {
      if (!activePromptData.id) {
        setSnackbarMessage("Please select a prompt to edit");
        setSnackbarKey((prev) => prev + 1);
        setSnackbarType("error");
        setLoading(false);
        return;
      }
      if (activePromptData.name === "" || activePromptData.content === "") {
        setSnackbarMessage("Please enter a name and prompt");
        setSnackbarKey((prev) => prev + 1);
        setSnackbarType("error");
        setLoading(false);
        return;
      }
      const url = new URL(backendApiUrl + "/templates/update_template");
      const response = await fetch(url.toString(), {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          template_id: activePromptData.id,
          template: activePromptData.content,
          name: activePromptData.name,
          shared: activePromptData.shared,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Response from backend:", data);
        fetchDropdownItems();
        setSnackbarMessage("Saved changes");
        setSnackbarType("success");
        setSnackbarKey((prev) => prev + 1);
        clearStorageAfterOperation();
      } else {
        console.error("Error calling backend:", response.statusText);
      }
    }
    setLoading(false);
  };

  // Add a helper function to determine if the save button should be disabled
  const isSaveButtonDisabled = () => {
    if (loading) return true;
    if (editorType === "edit" && !isOwnedByUser) return true;
    return false;
  };

  return (
    <SectionWrapper
      title="Create, edit, or delete prompts"
      description="Create a prompt, edit an existing one, or delete a prompt."
    >
      <FormContainer className="gap-4">
        <FormLabel
          label="Editor type"
          description="Choose the type of editor you want to use."
        />
        <FormContent>
          <Toggle
            options={[
              { caption: "Create", value: "create" },
              { caption: "Edit", value: "edit" },
              { caption: "Delete", value: "delete" },
            ]}
            onSelect={handleEditorTypeSelect}
          />
        </FormContent>
      </FormContainer>

      {(editorType === "edit" || editorType === "delete") && (
        <FormContainer className="gap-4">
          <FormLabel
            label="Prompt"
            description={`Select the prompt you want to ${editorType}.`}
            required={true}
          />
          <FormContent>
            <DropDown
              items={dropdownItems}
              onSelect={handlePromptSelect}
              placeholder="Select a prompt"
              defaultSelected={activePromptData.id}
            />
          </FormContent>
        </FormContainer>
      )}

      {editorType !== "delete" && (
        <>
          <FormContainer className="gap-4">
            <FormLabel
              label="Name"
              description="Name of your prompt."
              required={true}
            />
            <FormContent>
              <TextField
                name="prompt_name"
                ariaLabel="Name of your prompt"
                placeholder="Prompt name"
                onChange={handlePromptNameChange}
                showmaxChars={false}
                initialValue={activePromptData.name}
                disabled={editorType === "edit" && !isOwnedByUser}
              />
            </FormContent>
          </FormContainer>

          <FormContainer className="gap-4">
            <FormLabel
              label="Share with team"
              description="Make this template available to all team members."
              required={false}
            />
            <FormContent>
              <ToggleSwitch
                id="share-toggle"
                onToggle={handleSharedToggle}
                initialState={activePromptData.shared}
                label="Share with team"
                disabled={editorType === "edit" && !isOwnedByUser}
              />
            </FormContent>
          </FormContainer>

          <FormContainer className="gap-4">
            <FormLabel
              label="Prompt"
              description="Write your prompt here."
              required={true}
            />
            {tool === "LMG" ? (
              <PromptEditor
                defaultPrompt={activePromptData.content}
                onPromptChange={handlePromptChange}
                readOnly={editorType === "edit" && !isOwnedByUser}
              />
            ) : (
              <TextField
                name="prompt"
                ariaLabel="Prompt"
                placeholder="Write your prompt here."
                maxChars={20000}
                onChange={handlePromptChange}
                fixedHeight={true}
                initialValue={activePromptData.content}
                disabled={editorType === "edit" && !isOwnedByUser}
              />
            )}
          </FormContainer>
        </>
      )}

      {editorType === "edit" && !isOwnedByUser && (
        <div className="mb-4 p-3 bg-yellow-50 border border-yellow-200 rounded-md text-sm text-yellow-800">
          This is a team prompt shared by another team member. You can view it
          but cannot edit it.
        </div>
      )}

      <FormContainer className="gap-4">
        <FormLabel
          label={editorType === "delete" ? "Delete" : "Save"}
          description={`${editorType === "delete" ? "Delete" : "Save"} your prompt here.`}
        />
        <FormContent>
          <div className="flex flex-col gap-2">
            {editorType === "edit" && !isOwnedByUser && (
              <p className="text-sm text-gray-600">
                You cannot edit prompts created by other team members.
              </p>
            )}
            <div className="flex flex-wrap gap-8">
              <Button
                variant="primary"
                type="button"
                size="md"
                onClick={handleSubmit}
                disabled={isSaveButtonDisabled()}
              >
                {loading ? (
                  <Spinner />
                ) : (
                  <img
                    src={editorType === "delete" ? deleteIcon : saveIcon}
                    alt={editorType === "delete" ? "Delete" : "Save"}
                    className="w-5 h-5"
                  />
                )}
                {loading
                  ? `${editorType === "delete" ? "Deleting..." : "Saving..."}`
                  : `${editorType === "delete" ? "Delete" : "Save"}`}
              </Button>
            </div>
          </div>
        </FormContent>
      </FormContainer>

      {snackbarMessage && (
        <CustomizedSnackbars
          message={snackbarMessage}
          type={snackbarType}
          key={snackbarKey}
        />
      )}
    </SectionWrapper>
  );
};

export default CreateSection;
