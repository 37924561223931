import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "contexts/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import Header from "components/Common/Header";
import SectionWrapper from "components/Common/SectionWrapper";
import Button from "components/Forms/Button";
import FormContainer from "components/Forms/FormContainer";
import Spinner from "components/Forms/Spinner";
import CustomizedSnackbars from "components/Forms/Snackbar";

interface WebflowHistoryItem {
  id: string;
  isDraft: boolean;
  slug: string;
  name: string;
  author: string[];
  lastPublished?: string;
  createdOn: string;
}

const WebflowHistoryPage: React.FC = () => {
  const { userData } = useUserContext();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const [history, setHistory] = useState<WebflowHistoryItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarType, setSnackbarType] = useState<
    "success" | "error" | "warning" | "info"
  >("error");

  useEffect(() => {
    fetchHistory();
  }, []);

  const fetchHistory = async () => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const backendUrl = process.env.REACT_APP_BACKEND_API_URL;

      const response = await fetch(`${backendUrl}/webflow/history`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (data.success) {
        setHistory(data.history || []);
      } else {
        throw new Error(data.message || "Failed to fetch history");
      }
    } catch (error: unknown) {
      const errorMsg =
        error instanceof Error ? error.message : "Failed to fetch history";
      setErrorMessage(errorMsg);
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
    } finally {
      setIsLoading(false);
    }
  };

  // Check if user has Webflow module access
  if (!userData?.preferences?.modules?.includes("WEBFLOW")) {
    return (
      <div className="p-8 text-center">
        <h1 className="text-xl font-bold">Access Denied</h1>
        <p className="mt-4">You don't have access to the Webflow module.</p>
      </div>
    );
  }

  return (
    <>
      <Header title="Webflow Article History" />

      <SectionWrapper title="Published Articles">
        <FormContainer className="gap-4 w-full">
          <div className="flex justify-between items-center w-full mb-4">
            <Button
              type="button"
              variant="primary"
              size="md"
              onClick={() => navigate("/webflow")}
            >
              Create New Article
            </Button>
          </div>

          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <Spinner />
            </div>
          ) : history.length === 0 ? (
            <div className="text-center py-10">
              <p className="text-lg">No published articles found.</p>
              <Button
                type="button"
                variant="primary"
                size="md"
                onClick={() => navigate("/webflow")}
                className="mt-4"
              >
                Create Your First Article
              </Button>
            </div>
          ) : (
            <div className="overflow-x-auto w-full">
              <table className="min-w-full border-collapse">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="px-4 py-2 text-left">Title</th>
                    <th className="px-4 py-2 text-left">Status</th>
                    <th className="px-4 py-2 text-left">Created On</th>
                    <th className="px-4 py-2 text-left">Last Published</th>
                    <th className="px-4 py-2 text-left">Visit</th>
                  </tr>
                </thead>
                <tbody>
                  {history.map((item) => (
                    <tr key={item.id} className="border-t border-gray-200">
                      <td className="px-4 py-2">{item.name}</td>
                      <td className="px-4 py-2">
                        <span
                          className={`px-2 py-1 text-xs rounded-full ${
                            item.isDraft
                              ? "bg-yellow-100 text-yellow-800"
                              : "bg-green-100 text-green-800"
                          }`}
                        >
                          {item.isDraft ? "Draft" : "Published"}
                        </span>
                      </td>
                      <td className="px-4 py-2">
                        {new Date(item.createdOn).toLocaleDateString()}
                      </td>
                      <td className="px-4 py-2">
                        {item.lastPublished
                          ? new Date(item.lastPublished).toLocaleDateString()
                          : "-"}
                      </td>
                      <td className="px-4 py-2">
                        <a
                          href={`https://decaid-website.design.webflow.com/?locale=en&pageId=67d9b118d2e93d169c63da7f&itemId=67e32142ccfc9c523c8012a3&workflow=cms`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-blue-500 hover:underline"
                        >
                          View
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </FormContainer>
      </SectionWrapper>

      {errorMessage && (
        <CustomizedSnackbars
          message={errorMessage}
          type={snackbarType}
          key={snackbarKey}
        />
      )}
    </>
  );
};

export default WebflowHistoryPage;
