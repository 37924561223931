import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import Layout from "components/Layout/Layout";
import LoginPage from "pages/LoginPage";
import HomePage from "pages/HomePage";
import CopywritingPage from "pages/CopywritingPage";
import ResearchPage from "pages/ResearchPage";
import ResearchResultsPage from "pages/ResearchResultsPage";
import DesignPage from "pages/DesignPage";
import LMGPage from "pages/LMGPage";
import SettingsPage from "pages/SettingsPage";
import SupportPage from "pages/SupportPage";
import PromptPage from "pages/PromptPage";
import RSSPage from "pages/RSSFeed";
import WebflowPage from "pages/WebflowPage";
import WebflowHistoryPage from "pages/WebflowHistoryPage";
import FluxPage from "pages/FluxPage";
import FluxHistoryPage from "pages/FluxHistoryPage";
import { UserProvider, useUserContext } from "contexts/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import ProtectedRoute from "components/ProtectedRoute";

import logo from "assets/images/logo.svg";
import userAvatar from "assets/images/userAvatar.png";
import homeIcon from "assets/images/home-line.svg";
import createIcon from "assets/images/edit-04.svg";
import researchIcon from "assets/images/search-refraction.svg";
import searchIcon from "assets/images/search-refraction.svg";
import designIcon from "assets/images/palette.svg";
import lmgIcon from "assets/images/linkedin-icon-grey.svg";
import rssIcon from "assets/images/rss.svg";
import templateIcon from "assets/images/template-icon.svg";
import historyIcon from "assets/images/history-icon.svg";
import AdminPanel from "pages/AdminPanel";
import HistoryPage from "pages/HistoryPage";
import DeletionPage from "pages/DeletionPage";
import SentimentAnalysisPage from "pages/SentimentAnalysisPage";
import fluxIcon from "assets/images/palette.svg";

function AppContent() {
  const navigate = useNavigate();
  const { userData, avatarUrl, refreshUserData, clearUserData } =
    useUserContext();
  const { logout } = useAuth0();

  const modules = userData?.preferences?.modules || [];

  const allNavItems = [
    { icon: homeIcon, label: "Home", path: "/home", moduleId: "home" },
    {
      icon: createIcon,
      label: "Copywriting",
      path: "/copywriting",
      moduleId: "COPYWRITING",
      tooltip:
        "Tool for generating copywriting content for various platforms matching your brand voice.",
      subItems: [
        {
          icon: templateIcon,
          label: "Prompt Templates",
          path: "/prompt/copywriting",
          moduleId: "COPYWRITING",
        },
        {
          icon: historyIcon,
          label: "History",
          path: "/history/copywriting",
          moduleId: "COPYWRITING",
        },
      ],
    },
    {
      icon: searchIcon,
      label: "Sentiment Analysis",
      path: "/sentiment-analysis",
      moduleId: "SENTIMENT_ANALYSIS",
      tooltip: "Tool for analyzing the sentiment of a given text.",
      subItems: [
        {
          icon: historyIcon,
          label: "History",
          path: "/history/sentiment-analysis",
          moduleId: "SENTIMENT_ANALYSIS",
        },
      ],
    },
    {
      icon: researchIcon,
      label: "Research",
      path: "/research",
      moduleId: "research",
    },
    { icon: designIcon, label: "Design", path: "/design", moduleId: "design" },
    {
      icon: lmgIcon,
      label: "Cold Outreach",
      path: "/lmg",
      moduleId: "LMG",
      tooltip:
        "Tool for generating highly personalized cold outreach messages on LinkedIn.",
      subItems: [
        {
          icon: templateIcon,
          label: "Prompt Templates",
          path: "/prompt/lmg",
          moduleId: "LMG",
        },
      ],
    },
    {
      icon: rssIcon,
      label: "RSS Feed",
      path: "/rss-feed",
      moduleId: "RSS_FEED",
      tooltip: "Tool for viewing and filtering RSS news feeds.",
    },
    {
      icon: createIcon,
      label: "Webflow",
      path: "/webflow",
      moduleId: "WEBFLOW",
      tooltip: "Tool for transforming content to Webflow articles.",
      subItems: [
        {
          icon: historyIcon,
          label: "History",
          path: "/webflow-history",
          moduleId: "WEBFLOW",
        },
      ],
    },
    {
      icon: fluxIcon,
      label: "Image Generator",
      path: "/flux",
      moduleId: "FLUX",
      tooltip:
        "Generate AI images from text prompts with customizable options.",
      subItems: [
        {
          icon: historyIcon,
          label: "History",
          path: "/flux/history",
          moduleId: "FLUX",
        },
      ],
    },
  ];

  const sidebarProps = {
    logo: logo,
    navItems: [
      ...allNavItems.filter(
        (item) => item.moduleId === "home" || modules.includes(item.moduleId)
      ),
    ],
    userProfile: {
      avatar: avatarUrl || userAvatar,
      name: `${userData?.first_name || "Example"} ${userData?.last_name || "User"}`,
      email: userData?.email || "example@user.com",
      onLogout: () => {
        console.log("Logout clicked");
        // Clear user data before logging out
        clearUserData();
        logout({
          logoutParams: { returnTo: window.location.origin },
        });
        navigate("/login");
      },
    },
    onSearchInputChange: (value: string) => {
      console.log("Search input changed:", value);
    },
    onSearchSubmit: (value: string) => {
      console.log("Search submitted:", value);
    },
  };

  // Refresh user data when navigating to a new page
  useEffect(() => {
    // Only refresh if the user is logged in
    if (userData) {
      refreshUserData().catch((error) => {
        console.error("Failed to refresh user data:", error);
      });
    }
  }, [navigate]);

  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/" element={<Navigate to="/login" replace />} />
      <Route
        element={
          <ProtectedRoute>
            <Layout sidebarProps={sidebarProps} />
          </ProtectedRoute>
        }
      >
        <Route path="/home" element={<HomePage />} />
        <Route path="/copywriting" element={<CopywritingPage />} />
        <Route path="/sentiment-analysis" element={<SentimentAnalysisPage />} />
        <Route
          path="/history/sentiment-analysis"
          element={<HistoryPage tool="SENTIMENT_ANALYSIS" />}
        />
        <Route path="/research" element={<ResearchPage />} />
        <Route path="/research/results" element={<ResearchResultsPage />} />
        <Route path="/design" element={<DesignPage />} />
        <Route path="/lmg" element={<LMGPage />} />
        <Route path="/prompt/lmg" element={<PromptPage tool="LMG" />} />
        <Route
          path="/prompt/copywriting"
          element={<PromptPage tool="COPYWRITING" />}
        />
        <Route
          path="/history/copywriting"
          element={<HistoryPage tool="COPYWRITING" />}
        />
        <Route path="/deletion" element={<DeletionPage />} />
        <Route path="/admin" element={<AdminPanel />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/rss-feed" element={<RSSPage />} />
        <Route path="/webflow" element={<WebflowPage />} />
        <Route path="/webflow-history" element={<WebflowHistoryPage />} />
        <Route path="/flux" element={<FluxPage />} />
        <Route path="/flux/history" element={<FluxHistoryPage />} />
      </Route>
    </Routes>
  );
}

function App() {
  return (
    <Router>
      <UserProvider>
        <AppContent />
      </UserProvider>
    </Router>
  );
}

export default App;
