import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import Header from "components/Common/Header";
import { useUserContext } from "contexts/UserContext";

// Import custom components
import SectionWrapper from "components/Common/SectionWrapper";
import FormContainer from "components/Forms/FormContainer";
import FormContent from "components/Forms/FormContent";
import FormLabel from "components/Forms/FormLabel";
import TextField from "components/Forms/TextField";
import Button from "components/Forms/Button";
import DropDown, { DropdownItem } from "components/Forms/DropDown";
import ToggleSwitch from "components/Forms/ToggleSwitch";
import ProgressBar from "components/Forms/ProgressBar";
import CustomizedSnackbars from "components/Forms/Snackbar";

// Import assets
import generateIcon from "assets/images/star-06-white.svg";

const FluxPage: React.FC = () => {
  const {
    getAccessTokenSilently,
    isAuthenticated,
    isLoading: authLoading,
  } = useAuth0();
  const navigate = useNavigate();
  const { userData } = useUserContext();

  const [prompt, setPrompt] = useState("");
  const [aspectRatio, setAspectRatio] = useState("1:1");
  const [enhancePrompt, setEnhancePrompt] = useState(true);
  const [shareWithTeam, setShareWithTeam] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [generatedImage, setGeneratedImage] = useState<any>(null);
  const [statusMessage, setStatusMessage] = useState("");
  const [progressPercentage, setProgressPercentage] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarType, setSnackbarType] = useState<
    "success" | "error" | "warning" | "info"
  >("error");

  const aspectRatioOptions: DropdownItem[] = [
    { label: "Square (1:1)", value: "1:1" },
    { label: "Landscape (3:2)", value: "3:2" },
    { label: "Portrait (2:3)", value: "2:3" },
    { label: "Widescreen (16:9)", value: "16:9" },
    { label: "Mobile (9:16)", value: "9:16" },
  ];

  const handleGenerateImage = async () => {
    if (!prompt) {
      setErrorMessage("Please enter an image description");
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
      return;
    }

    try {
      setIsGenerating(true);
      setStatusMessage("Generating your image...");
      setProgressPercentage(30);

      // Simulate progress for UX
      const interval = setInterval(() => {
        setProgressPercentage((prev) => {
          const newProgress = prev + 5;
          if (newProgress >= 90) {
            clearInterval(interval);
            return 90;
          }
          return newProgress;
        });
      }, 500);

      // Get the access token from Auth0
      const token = await getAccessTokenSilently();

      // Make the API call
      const backend_api_url = process.env.REACT_APP_BACKEND_API_URL;
      if (!backend_api_url) {
        throw new Error("Backend API URL is not defined");
      }

      const response = await fetch(`${backend_api_url}/flux/generate`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          prompt: prompt,
          enhance: enhancePrompt,
          shared: shareWithTeam,
          company_id: userData?.uuid || "",
          aspect_ratio: aspectRatio,
        }),
      });

      clearInterval(interval);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.detail || "Failed to generate image");
      }

      const imageData = await response.json();
      setProgressPercentage(100);
      setGeneratedImage(imageData);

      setErrorMessage("Image generated successfully!");
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("success");

      setIsGenerating(false);
      setStatusMessage("");
      setProgressPercentage(0);
    } catch (error) {
      console.error("Error generating image:", error);
      setErrorMessage(
        error instanceof Error ? error.message : "Failed to generate image"
      );
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
      setIsGenerating(false);
      setStatusMessage("");
      setProgressPercentage(0);
    }
  };

  const handleAspectRatioSelect = (item: DropdownItem) => {
    setAspectRatio(item.value);
  };

  const handleViewHistory = () => {
    navigate("/flux/history");
  };

  if (authLoading) {
    return (
      <>
        <Header title="Image Generator" />
        <div className="flex justify-center items-center h-[80vh]">
          <ProgressBar progress={50} statusMessage="Loading..." />
        </div>
      </>
    );
  }

  if (!isAuthenticated) {
    navigate("/login");
    return null;
  }

  return (
    <>
      <Header title="Image Generator" />
      <div className="p-6">
        <p className="mb-6">
          Create beautiful AI-generated images from your text prompts
        </p>

        <SectionWrapper
          title="Generate Image"
          description="Enter a description of the image you want to create"
        >
          <FormContainer className="gap-4">
            <FormLabel
              label="Image Description"
              description="Describe what you want to see in your image"
              required
            />
            <FormContent>
              <TextField
                name="prompt"
                ariaLabel="image-description"
                placeholder="Describe the image you want to generate..."
                onChange={(value) => setPrompt(value)}
                fixedHeight={false}
              />
            </FormContent>
          </FormContainer>

          <FormContainer className="gap-4">
            <FormLabel
              label="Aspect Ratio"
              description="Choose the aspect ratio for your image"
            />
            <FormContent>
              <DropDown
                items={aspectRatioOptions}
                onSelect={handleAspectRatioSelect}
                defaultSelected={aspectRatio}
              />
            </FormContent>
          </FormContainer>

          <FormContainer className="gap-4">
            <FormLabel
              label="Enhance Prompt"
              description="Automatically enhance your prompt with additional details"
            />
            <FormContent>
              <ToggleSwitch
                label="Enhance prompt"
                id="enhancePrompt"
                onToggle={setEnhancePrompt}
                initialState={enhancePrompt}
              />
            </FormContent>
          </FormContainer>

          <FormContainer className="gap-4">
            <FormLabel
              label="Share with Team"
              description="Make this image available to your team members"
            />
            <FormContent>
              <ToggleSwitch
                label="Share with team"
                id="shareWithTeam"
                onToggle={setShareWithTeam}
                initialState={shareWithTeam}
              />
            </FormContent>
          </FormContainer>

          <FormContainer className="mt-8 justify-center">
            {isGenerating ? (
              <ProgressBar
                progress={progressPercentage}
                statusMessage={statusMessage || "Generating your image..."}
              />
            ) : (
              <Button
                variant="primary"
                type="button"
                size="md"
                onClick={handleGenerateImage}
                disabled={isGenerating}
              >
                <img src={generateIcon} alt="Generate" className="w-5 h-5" />
                Generate Image
              </Button>
            )}
          </FormContainer>
        </SectionWrapper>

        {generatedImage && (
          <SectionWrapper
            title="Generated Image"
            description="View your generated image"
          >
            <div className="flex flex-col md:flex-row gap-6 mt-4">
              <div className="md:w-1/2">
                <img
                  src={generatedImage.image_url}
                  alt={generatedImage.original_prompt}
                  className="w-full h-auto rounded-lg border border-gray-200 shadow-sm"
                />
              </div>
              <div className="md:w-1/2 flex flex-col gap-4">
                <div>
                  <h3 className="text-lg font-medium mb-1">Original Prompt</h3>
                  <p className="text-gray-700">
                    {generatedImage.original_prompt}
                  </p>
                </div>
                {generatedImage.enhanced_prompt !==
                  generatedImage.original_prompt && (
                  <div>
                    <h3 className="text-lg font-medium mb-1">
                      Enhanced Prompt
                    </h3>
                    <p className="text-gray-700">
                      {generatedImage.enhanced_prompt}
                    </p>
                  </div>
                )}
                <div className="mt-auto flex flex-wrap gap-2">
                  <Button
                    variant="secondary"
                    type="button"
                    size="sm"
                    onClick={() =>
                      window.open(generatedImage.image_url, "_blank")
                    }
                  >
                    Open Image
                  </Button>
                  <Button
                    variant="secondary"
                    type="button"
                    size="sm"
                    onClick={() =>
                      navigate(`/flux/history?image=${generatedImage.uuid}`)
                    }
                  >
                    View in History
                  </Button>
                </div>
              </div>
            </div>
          </SectionWrapper>
        )}
      </div>

      {errorMessage && (
        <CustomizedSnackbars
          message={errorMessage}
          type={snackbarType}
          key={snackbarKey}
        />
      )}
    </>
  );
};

export default FluxPage;
