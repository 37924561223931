import React, { useState, useMemo } from "react";
import { useLocation } from "react-router-dom";

import socialLoginIconGoogle from "assets/images/social-login-icon-google.svg";
import InputField from "components/Forms/InputField";
import Button from "components/Forms/Button";

interface LoginFormProps {
  onSubmit: () => void;
  onGoogleSignIn: () => void;
  onForgotPassword: () => void;
  onSignUp: () => void;
}

const LoginForm: React.FC<LoginFormProps> = ({
  onSubmit,
  // onGoogleSignIn,
  // onForgotPassword,
  onSignUp,
}) => {
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  // const [rememberMe, setRememberMe] = useState(false);
  const location = useLocation();

  const isSignUp = useMemo(() => {
    console.debug("location.search", location.search);
    return location.search.includes("invitation");
  }, [location]);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (isSignUp) {
      onSignUp();
    } else {
      onSubmit();
    }
  };

  return (
    <div className="flex flex-col max-w-[360px] w-[360px]">
      <div className="flex flex-col w-full">
        <h1 className="text-3xl font-semibold leading-10 text-gray-900">
          {isSignUp ? "Create your account" : "Welcome back to DECAID Forge!"}
        </h1>
        <p className="mt-3 text-base leading-6 text-slate-600">
          {isSignUp
            ? "Get started with DECAID Forge."
            : "Please log yourself in."}
        </p>
      </div>
      <form className="flex flex-col mt-6 w-full" onSubmit={handleSubmit}>
        <Button type="submit" variant="primary" size="lg" className="mt-6">
          {isSignUp ? "Sign up" : "Sign in"}
        </Button>
      </form>
      {!isSignUp && (
        <p className="flex gap-1 justify-center items-start mt-8 w-full text-sm leading-5">
          <span className="text-slate-600">Don't have an account?</span>
          <a
            href="#"
            className="overflow-hidden gap-1.5 self-stretch font-semibold text-violet-700"
            onClick={(e) => {
              e.preventDefault();
              onSignUp();
            }}
          >
            Sign up
          </a>
        </p>
      )}
    </div>
  );
};

export default LoginForm;
