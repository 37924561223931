import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "contexts/UserContext";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "components/Forms/Button";
import FormContainer from "components/Forms/FormContainer";
import FormContent from "components/Forms/FormContent";
import FormLabel from "components/Forms/FormLabel";
import SectionWrapper from "components/Common/SectionWrapper";
import Header from "components/Common/Header";
import Spinner from "components/Forms/Spinner";
import CustomizedSnackbars from "components/Forms/Snackbar";

interface TransformedContent {
  name: string;
  slug: string;
  previewtext: string;
  inhalt: string;
}

interface Author {
  id: string;
  name: string;
  picture: {
    url: string;
  };
  slug: string;
  bio_summary: string;
}

interface FluxImage {
  uuid: string;
  image_url: string;
  prompt: string;
  shared: boolean;
  created_at: string;
}

const WebflowPage: React.FC = () => {
  const { userData } = useUserContext();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [transformedContent, setTransformedContent] =
    useState<TransformedContent | null>(null);
  const [entryId, setEntryId] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [snackbarKey, setSnackbarKey] = useState(0);
  const [snackbarType, setSnackbarType] = useState<
    "success" | "error" | "warning" | "info"
  >("error");
  const [authors, setAuthors] = useState<Author[]>([]);
  const [selectedAuthorId, setSelectedAuthorId] = useState<string>("");
  const [loadingAuthors, setLoadingAuthors] = useState(false);
  const [fluxImages, setFluxImages] = useState<FluxImage[]>([]);
  const [loadingImages, setLoadingImages] = useState(false);
  const [selectedImageUuid, setSelectedImageUuid] = useState<string>("");
  const [previewImageUrl, setPreviewImageUrl] = useState<string>("");
  const [showImageSelector, setShowImageSelector] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Load content from session storage if available (from Copywriting page)
  useEffect(() => {
    const savedContent = sessionStorage.getItem("webflow_content");
    if (savedContent) {
      setContent(savedContent);
      // Clear it after loading to avoid reloading on subsequent visits
      sessionStorage.removeItem("webflow_content");
    }
    // Fetch authors and images
    fetchAuthors();
    fetchFluxImages();
  }, []);

  // Update preview image when selection changes
  useEffect(() => {
    if (selectedImageUuid) {
      const selectedImage = fluxImages.find(
        (image) => image.uuid === selectedImageUuid
      );
      if (selectedImage) {
        setPreviewImageUrl(selectedImage.image_url);
      }
    } else {
      setPreviewImageUrl("");
    }
  }, [selectedImageUuid, fluxImages]);

  const fetchAuthors = async () => {
    setLoadingAuthors(true);
    try {
      const token = await getAccessTokenSilently();
      const backendUrl = process.env.REACT_APP_BACKEND_API_URL;

      const response = await fetch(`${backendUrl}/webflow/authors`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (data.success) {
        setAuthors(data.authors);
        // Select the first author by default if available
        if (data.authors && data.authors.length > 0) {
          setSelectedAuthorId(data.authors[0].id);
        }
      } else {
        throw new Error(data.message || "Failed to fetch authors");
      }
    } catch (error: unknown) {
      const errorMsg =
        error instanceof Error
          ? error.message
          : "An error occurred while fetching authors";
      setErrorMessage(errorMsg);
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
    } finally {
      setLoadingAuthors(false);
    }
  };

  const fetchFluxImages = async () => {
    setLoadingImages(true);
    try {
      const token = await getAccessTokenSilently();
      const backendUrl = process.env.REACT_APP_BACKEND_API_URL;

      const response = await fetch(`${backendUrl}/webflow/flux-images`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (data.success) {
        setFluxImages(data.images);
      } else {
        throw new Error(data.message || "Failed to fetch images");
      }
    } catch (error: unknown) {
      const errorMsg =
        error instanceof Error
          ? error.message
          : "An error occurred while fetching images";
      setErrorMessage(errorMsg);
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
    } finally {
      setLoadingImages(false);
    }
  };

  const handleTransform = async () => {
    if (!content.trim()) {
      setErrorMessage("Please enter content to transform");
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
      return;
    }

    setIsLoading(true);

    try {
      const token = await getAccessTokenSilently();
      const backendUrl = process.env.REACT_APP_BACKEND_API_URL;

      const response = await fetch(`${backendUrl}/webflow/transform`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          content,
          author_id: selectedAuthorId,
          image_uuid: selectedImageUuid,
        }),
      });

      const data = await response.json();

      if (data.success) {
        setTransformedContent(data.article_data);
        setEntryId(data.entry_id);
        setErrorMessage("Content transformed successfully");
        setSnackbarKey((prev) => prev + 1);
        setSnackbarType("success");
      } else {
        throw new Error(data.message || "Failed to transform content");
      }
    } catch (error: unknown) {
      const errorMsg =
        error instanceof Error
          ? error.message
          : "An error occurred while transforming the content";
      setErrorMessage(errorMsg);
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePublish = async () => {
    if (!entryId) return;

    setIsLoading(true);

    try {
      const token = await getAccessTokenSilently();
      const backendUrl = process.env.REACT_APP_BACKEND_API_URL;

      const response = await fetch(`${backendUrl}/webflow/publish/${entryId}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();

      if (data.success) {
        setErrorMessage("Content published to Webflow successfully");
        setSnackbarKey((prev) => prev + 1);
        setSnackbarType("success");
        // Navigate to history page
        navigate("/webflow-history");
      } else {
        throw new Error(data.message || "Failed to publish to Webflow");
      }
    } catch (error: unknown) {
      const errorMsg =
        error instanceof Error
          ? error.message
          : "An error occurred while publishing to Webflow";
      setErrorMessage(errorMsg);
      setSnackbarKey((prev) => prev + 1);
      setSnackbarType("error");
    } finally {
      setIsLoading(false);
    }
  };

  // Function to handle image navigation in carousel
  const navigateImage = (direction: "prev" | "next") => {
    if (fluxImages.length === 0) return;

    if (direction === "prev") {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === 0 ? fluxImages.length - 1 : prevIndex - 1
      );
    } else {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === fluxImages.length - 1 ? 0 : prevIndex + 1
      );
    }
  };

  // Effect to update selected image when navigating carousel
  useEffect(() => {
    if (
      fluxImages.length > 0 &&
      currentImageIndex >= 0 &&
      currentImageIndex < fluxImages.length
    ) {
      setSelectedImageUuid(fluxImages[currentImageIndex].uuid);
    }
  }, [currentImageIndex, fluxImages]);

  // Check if user has Webflow module access
  if (!userData?.preferences?.modules?.includes("WEBFLOW")) {
    return (
      <div className="p-8 text-center">
        <h1 className="text-xl font-bold">Access Denied</h1>
        <p className="mt-4">You don't have access to the Webflow module.</p>
      </div>
    );
  }

  return (
    <>
      <Header title="Transform Content for Webflow" />

      <SectionWrapper title="Content Input">
        <FormContainer className="gap-4">
          <FormLabel
            label="Enter your content"
            description="Paste the content you want to transform for Webflow"
          />
          <FormContent>
            <textarea
              value={content}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setContent(e.target.value)
              }
              placeholder="Paste your content here..."
              className="w-full h-48 p-3 border border-gray-300 rounded-md"
              disabled={isLoading}
            />
          </FormContent>
        </FormContainer>
      </SectionWrapper>

      <SectionWrapper title="Article Settings">
        <FormContainer className="gap-4">
          <FormLabel
            label="Select Author"
            description="Choose the author for this article"
          />
          <FormContent>
            {loadingAuthors ? (
              <div className="flex items-center mt-2">
                <Spinner className="w-4 h-4" />{" "}
                <span className="ml-2">Loading authors...</span>
              </div>
            ) : (
              <select
                value={selectedAuthorId}
                onChange={(e) => setSelectedAuthorId(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md"
                disabled={isLoading || authors.length === 0}
              >
                {authors.length === 0 ? (
                  <option value="">No authors available</option>
                ) : (
                  authors.map((author) => (
                    <option key={author.id} value={author.id}>
                      {author.name}
                    </option>
                  ))
                )}
              </select>
            )}
          </FormContent>
        </FormContainer>

        <FormContainer className="gap-4 mt-4">
          <FormLabel
            label="Article Image"
            description="Add an image to your article from your Flux library"
          />
          <FormContent>
            <div className="flex items-center">
              <div className="relative inline-block w-10 mr-2 align-middle select-none">
                <input
                  type="checkbox"
                  id="toggle-image"
                  checked={showImageSelector}
                  onChange={() => {
                    setShowImageSelector(!showImageSelector);
                    if (!showImageSelector) {
                      // If turning on, select the first image if available
                      if (fluxImages.length > 0) {
                        setSelectedImageUuid(fluxImages[0].uuid);
                        setCurrentImageIndex(0);
                      }
                    } else {
                      // If turning off, clear selection
                      setSelectedImageUuid("");
                    }
                  }}
                  className="sr-only"
                />
                <label
                  htmlFor="toggle-image"
                  className={`block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer ${
                    showImageSelector ? "bg-blue-600" : ""
                  }`}
                >
                  <span
                    className={`block h-6 w-6 rounded-full bg-white shadow transform transition-transform duration-200 ease-in-out ${
                      showImageSelector ? "translate-x-4" : "translate-x-0"
                    }`}
                  ></span>
                </label>
              </div>
              <label
                htmlFor="toggle-image"
                className="text-sm font-medium cursor-pointer"
              >
                Include image
              </label>
            </div>

            {showImageSelector && (
              <div className="mt-4">
                {loadingImages ? (
                  <div className="flex items-center mt-2">
                    <Spinner className="w-4 h-4" />{" "}
                    <span className="ml-2">Loading images...</span>
                  </div>
                ) : fluxImages.length === 0 ? (
                  <div className="text-gray-500 mt-2">No images available</div>
                ) : (
                  <div className="mt-2">
                    {/* Image Carousel */}
                    <div className="relative">
                      {/* Left Arrow */}
                      <button
                        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 text-white rounded-l-md p-2 z-10"
                        onClick={() => navigateImage("prev")}
                      >
                        &#10094;
                      </button>

                      {/* Current Image */}
                      <div className="relative mx-auto w-full max-w-2xl overflow-hidden rounded-lg border border-gray-300">
                        <img
                          src={fluxImages[currentImageIndex]?.image_url}
                          alt={fluxImages[currentImageIndex]?.prompt}
                          className="w-full h-64 object-contain"
                        />
                        <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-50 text-white p-2 text-sm">
                          {fluxImages[currentImageIndex]?.prompt}
                        </div>
                      </div>

                      {/* Right Arrow */}
                      <button
                        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-800 bg-opacity-50 text-white rounded-r-md p-2 z-10"
                        onClick={() => navigateImage("next")}
                      >
                        &#10095;
                      </button>

                      {/* Image count indicator */}
                      <div className="absolute bottom-10 left-0 right-0 text-center">
                        <div className="inline-flex bg-black bg-opacity-50 rounded-full px-3 py-1 text-white text-xs">
                          {currentImageIndex + 1} / {fluxImages.length}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </FormContent>
        </FormContainer>
      </SectionWrapper>

      <FormContainer className="mt-8 justify-center">
        {isLoading ? (
          <Spinner />
        ) : (
          <Button
            type="button"
            variant="primary"
            size="md"
            onClick={handleTransform}
            disabled={
              !content.trim() ||
              !selectedAuthorId ||
              (showImageSelector && fluxImages.length > 0 && !selectedImageUuid)
            }
          >
            Transform Content
          </Button>
        )}
      </FormContainer>

      {transformedContent && (
        <SectionWrapper title="Preview Results">
          <FormContainer className="gap-4">
            <FormLabel
              label="Transformed Content Preview"
              description="Review the content before publishing to Webflow"
            />
            <FormContent>
              <div className="mb-4">
                <h3 className="font-semibold">Title:</h3>
                <p>{transformedContent.name}</p>
              </div>

              <div className="mb-4">
                <h3 className="font-semibold">Slug:</h3>
                <p>{transformedContent.slug}</p>
              </div>

              <div className="mb-4">
                <h3 className="font-semibold">Preview Text:</h3>
                <p>{transformedContent.previewtext}</p>
              </div>

              <div className="mb-4">
                <h3 className="font-semibold">Content:</h3>
                <div
                  className="p-3 mt-2 border border-gray-200 rounded-md max-h-72 overflow-y-auto"
                  dangerouslySetInnerHTML={{
                    __html: transformedContent.inhalt,
                  }}
                />
              </div>

              <div className="flex justify-end mt-4">
                {isLoading ? (
                  <Spinner />
                ) : (
                  <Button
                    type="button"
                    variant="primary"
                    size="md"
                    onClick={handlePublish}
                  >
                    Publish to Webflow
                  </Button>
                )}
              </div>
            </FormContent>
          </FormContainer>
        </SectionWrapper>
      )}

      {errorMessage && (
        <CustomizedSnackbars
          message={errorMessage}
          key={snackbarKey}
          type={snackbarType}
        />
      )}
    </>
  );
};

export default WebflowPage;
