import React, { useState, useRef, useCallback } from "react";
import UploadedFile from "./UploadedFile";
import defaultIcon from "assets/images/default-avatar.png";

interface AvatarUploadProps {
  onAvatarUpload: (file: File) => void;
  avatarUrl: string;
}

interface UploadingFile {
  file: File;
  progress: number;
  uploading: boolean;
  error?: string;
}

const AvatarUpload: React.FC<AvatarUploadProps> = ({
  onAvatarUpload,
  avatarUrl,
}) => {
  const [uploadingFiles, setUploadingFiles] = useState<UploadingFile[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const allowedFormats = ["png", "jpg", "jpeg"];

  const isFileFormatValid = useCallback(
    (file: File) => {
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      return (fileExtension && allowedFormats.includes(fileExtension)) || false;
    },
    [allowedFormats]
  );

  const handleFileSelection = useCallback(
    (files: FileList) => {
      const newFiles = Array.from(files).map((file) => ({
        file,
        progress: 0,
        uploading: isFileFormatValid(file),
        error: isFileFormatValid(file) ? undefined : "Unsupported file format",
      }));

      setUploadingFiles((prev) => [...prev, ...newFiles]);

      // Simulate upload for each valid file
      newFiles.forEach((file) => {
        if (!file.error) {
          simulateUpload(file);
        }
      });
    },
    [isFileFormatValid, allowedFormats]
  );

  const simulateUpload = useCallback(
    (uploadingFile: UploadingFile) => {
      let progress = 0;
      const interval = setInterval(() => {
        progress += Math.random() * 30;
        if (progress >= 100) {
          progress = 100;
          clearInterval(interval);
          setUploadingFiles((prev) =>
            prev.map((f) =>
              f.file === uploadingFile.file
                ? { ...f, progress: 100, uploading: false }
                : f
            )
          );
          onAvatarUpload(uploadingFile.file);
        } else {
          setUploadingFiles((prev) =>
            prev.map((f) =>
              f.file === uploadingFile.file
                ? { ...f, progress: Math.round(progress) }
                : f
            )
          );
        }
      }, 500);
    },
    [onAvatarUpload]
  );

  const onDragOver = useCallback((event: React.DragEvent) => {
    event.preventDefault();
  }, []);

  const onDrop = useCallback(
    (event: React.DragEvent) => {
      event.preventDefault();
      handleFileSelection(event.dataTransfer.files);
    },
    [handleFileSelection]
  );

  const onClickUpload = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const onFileInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files) {
        handleFileSelection(event.target.files);
      }
    },
    [handleFileSelection]
  );

  return (
    <>
      <div
        className="flex items-start justify-start cursor-pointer"
        onClick={onClickUpload}
        onDragOver={onDragOver}
        onDrop={onDrop}
      >
        {avatarUrl ? (
          <img
            src={avatarUrl}
            alt="User Avatar"
            className="w-20 h-20 rounded-full object-cover border-2 border-gray-200 hover:border-violet-700 transition-colors"
          />
        ) : (
          <div className="w-20 h-20 rounded-full bg-gray-200 flex items-center justify-center text-gray-500 hover:bg-gray-300 transition-colors">
            <img
              src={defaultIcon}
              alt="Default Avatar"
              className="w-full h-full object-cover rounded-full"
            />
          </div>
        )}
      </div>

      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        onChange={onFileInputChange}
        accept={allowedFormats.join(",")}
      />

      {uploadingFiles.map((file, index) => (
        <UploadedFile
          key={index}
          fileName={file.file.name}
          fileSize={`${(file.file.size / (1024 * 1024)).toFixed(1)} MB`}
          fileType={file.file.name.split(".").pop() as any}
          uploadProgress={file.progress}
          uploadInProgress={file.uploading}
          error={file.error}
          onDelete={() =>
            setUploadingFiles((prev) => prev.filter((f) => f !== file))
          }
        />
      ))}
    </>
  );
};

export default AvatarUpload;
