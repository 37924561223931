import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  TextField,
  Button,
  FormGroup,
  FormControlLabel,
  Checkbox,
  IconButton,
  MobileStepper,
  CardActions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Paper,
  Avatar,
  Tooltip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import ShareIcon from "@mui/icons-material/Share";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import StarIcon from "@mui/icons-material/Star";
import dayjs from "dayjs";
import Header from "components/Common/Header";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

// Import mock data from JSON file
import mockArticlesData from "../assets/mock/mockArticles.json";

// Define types for our RSS feed articles
interface Image {
  url: string;
  title: string;
  width: number;
  height: number;
}

interface Premium {
  is_premium: boolean;
  type: string;
}

interface Tag {
  term: string;
  type: string;
}

interface Article {
  id: string;
  title: string;
  link: string;
  pub_date: string;
  author: string;
  teaser_html?: string;
  teaser_text: string;
  content_html?: string;
  content_text: string;
  images: Image[];
  tags?: Tag[];
  ressort: string;
  dachzeile?: string;
  subtitle?: string;
  type?: string;
  premium: Premium;
  change_date?: string | null;
  parsed_date: string;
}

// Cast the imported JSON to our Article type
const mockArticles: Article[] = mockArticlesData as Article[];

const RSSPage: React.FC = () => {
  const navigate = useNavigate();
  
  // State for articles and loading/error states
  const [articles, setArticles] = useState<Article[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  // State for sort order
  const [sortBy, setSortBy] = useState<string>("date");

  // State for filter values
  const [selectedRessort, setSelectedRessort] = useState<string>("");
  const [showPremium, setShowPremium] = useState<boolean>(false);
  const [startDate, setStartDate] = useState<dayjs.Dayjs | null>(
    dayjs().subtract(5, "day")
  );
  const [endDate, setEndDate] = useState<dayjs.Dayjs | null>(dayjs());
  
  // State for article dialog
  const [selectedArticle, setSelectedArticle] = useState<Article | null>(null);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  
  // Function to navigate to copywriting page with article content
  const navigateToCopywriting = (article: Article) => {
    const content = article.content_text || article.teaser_text;
    // Save the article content to session storage for the copywriting page
    sessionStorage.setItem('copywriting_inspiration', content);
    // Navigate to the copywriting page
    navigate('/copywriting');
  };

  // Get unique ressorts for filter dropdown
  const ressorts = Array.from(
    new Set(mockArticles.map((article) => article.ressort))
  );

  const fetchArticles = async () => {
    setLoading(true);
    setError(null);
    try {
      // Build query parameters for filtering
      const params = new URLSearchParams();
      if (startDate) {
        // Unix timestamp in seconds (not milliseconds)
        params.append("start_date", String(startDate.unix()));
        console.log("Start date timestamp:", startDate.unix());
      }
      if (endDate) {
        // Unix timestamp in seconds (not milliseconds)
        params.append("end_date", String(endDate.unix()));
        console.log("End date timestamp:", endDate.unix());
      }
      if (selectedRessort) {
        params.append("ressort", selectedRessort);
      }
      if (showPremium) {
        params.append("premium", "true");
      }

      // Get the auth token from localStorage
      const token = localStorage.getItem("authToken");

      if (!token) {
        console.error("No auth token found in localStorage");
        setError("Authentication token not found. Please log in again.");
        return;
      }

      console.log("Using token:", token.substring(0, 15) + "...");
      console.log(
        "Making request to:",
        `${process.env.REACT_APP_BACKEND_API_URL}/rss_feed/articles?${params.toString()}`
      );

      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_URL}/rss_feed/articles?${params.toString()}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      console.log("Response status:", response.status);

      if (response.status === 403) {
        console.error(
          "403 Forbidden error - token might be invalid or expired"
        );
        setError(
          "Access denied. Your session might have expired. Please try logging out and back in."
        );
        return;
      }

      if (!response.ok) {
        console.error("Error response:", response.status, response.statusText);
        throw new Error(
          `Failed to fetch articles: ${response.status} ${response.statusText}`
        );
      }

      const data = await response.json();
      console.log("Fetched articles:", data.length);
      setArticles(data);
    } catch (error) {
      console.error("Error fetching articles:", error);
      setError("Failed to load articles. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  // Call this function in useEffect or when filters change
  useEffect(() => {
    fetchArticles();
  }, [startDate, endDate, selectedRessort, showPremium, sortBy]);

  return (
    <div className="p-6">
      <Header title="RSS Feed" />

      {/* Filters section */}
      <Box
        sx={{
          mb: 4,
          p: 2,
          bgcolor: "background.paper",
          borderRadius: 1,
          boxShadow: 1,
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Filters
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3}>
            <FormControl fullWidth>
              <InputLabel id="ressort-select-label">Ressort</InputLabel>
              <Select
                labelId="ressort-select-label"
                id="ressort-select"
                value={selectedRessort}
                label="Ressort"
                onChange={(e) => setSelectedRessort(e.target.value)}
              >
                <MenuItem value="">All</MenuItem>
                {ressorts.map((ressort) => (
                  <MenuItem key={ressort} value={ressort}>
                    {ressort}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Start Date"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
                sx={{ width: "100%" }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="End Date"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
                sx={{ width: "100%" }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={12} md={3}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showPremium}
                    onChange={(e) => setShowPremium(e.target.checked)}
                  />
                }
                label="Premium Content Only"
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={3}>
        {articles.length > 0 ? (
          articles.map((article) => (
            <Grid item xs={12} sm={6} md={4} key={article.id}>
              <Card
                sx={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {article.images.length > 0 && (
                  <CardMedia
                    component="img"
                    height="140"
                    image={article.images[0].url}
                    alt={article.images[0].title || article.title}
                  />
                )}
                <CardContent sx={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      mb: 1,
                    }}
                  >
                    <Typography variant="caption" color="text.secondary">
                      {dayjs(article.pub_date).format("MMM D, YYYY")}
                    </Typography>
                    <Box>
                      {article.premium.is_premium && (
                        <Chip
                          label="Premium"
                          size="small"
                          color="primary"
                          sx={{ mr: 1 }}
                        />
                      )}
                      <Chip
                        label={article.ressort}
                        size="small"
                        variant="outlined"
                      />
                    </Box>
                  </Box>
                  <Typography gutterBottom variant="h5" component="div">
                    {article.title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {article.teaser_text.substring(0, 150)}
                    {article.teaser_text.length > 150 ? "..." : ""}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    size="small"
                    onClick={() => {
                      setSelectedArticle(article);
                      setDialogOpen(true);
                    }}
                  >
                    Read Full Article
                  </Button>
                  <Button
                    size="small"
                    endIcon={<OpenInNewIcon />}
                    onClick={() => window.open(article.link, "_blank")}
                  >
                    Original
                  </Button>
                  <Tooltip title="Use in Copywriting">
                    <IconButton
                      size="small"
                      color="primary"
                      onClick={() => navigateToCopywriting(article)}
                      sx={{ ml: 'auto' }}
                    >
                      <StarIcon />
                    </IconButton>
                  </Tooltip>
                  <IconButton size="small" color="default" aria-label="share">
                    <ShareIcon fontSize="small" />
                  </IconButton>
                </CardActions>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              No articles found matching your filters.
            </Typography>
          </Grid>
        )}
      </Grid>
      
      {/* Article Dialog */}
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="md"
        fullWidth
        scroll="paper"
        aria-labelledby="article-dialog-title"
      >
        {selectedArticle && (
          <>
            <DialogTitle id="article-dialog-title" sx={{ pr: 8 }}>
              {selectedArticle.title}
              <IconButton
                aria-label="close"
                onClick={() => setDialogOpen(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
              <Box sx={{ mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar sx={{ mr: 1, bgcolor: 'primary.main' }}>
                    {selectedArticle.author ? selectedArticle.author[0] : 'A'}
                  </Avatar>
                  <Box>
                    <Typography variant="subtitle1">{selectedArticle.author || 'Anonymous'}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      {dayjs(selectedArticle.pub_date).format("MMMM D, YYYY h:mm A")}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  {selectedArticle.premium.is_premium && (
                    <Chip label="Premium" size="small" color="primary" sx={{ mr: 1 }} />
                  )}
                  <Chip label={selectedArticle.ressort} size="small" variant="outlined" />
                </Box>
              </Box>
              
              {selectedArticle.images.length > 0 && (
                <Box sx={{ mb: 3, maxHeight: '400px', overflow: 'hidden', borderRadius: 1 }}>
                  <img
                    src={selectedArticle.images[0].url}
                    alt={selectedArticle.images[0].title || selectedArticle.title}
                    style={{ width: '100%', objectFit: 'cover' }}
                  />
                </Box>
              )}
              
              {selectedArticle.dachzeile && (
                <Typography variant="overline" color="text.secondary" gutterBottom>
                  {selectedArticle.dachzeile}
                </Typography>
              )}
              
              {selectedArticle.subtitle && (
                <Typography variant="h6" gutterBottom>
                  {selectedArticle.subtitle}
                </Typography>
              )}
              
              {selectedArticle.content_html ? (
                <div dangerouslySetInnerHTML={{ __html: selectedArticle.content_html }} />
              ) : (
                <Typography variant="body1" paragraph>
                  {selectedArticle.content_text || selectedArticle.teaser_text}
                </Typography>
              )}
              
              {selectedArticle.tags && selectedArticle.tags.length > 0 && (
                <Box sx={{ mt: 3 }}>
                  {selectedArticle.tags.map((tag) => (
                    <Chip
                      key={tag.term}
                      label={tag.term}
                      size="small"
                      variant="outlined"
                      sx={{ mr: 1, mb: 1 }}
                    />
                  ))}
                </Box>
              )}
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button onClick={() => setDialogOpen(false)}>Close</Button>
              <Button
                variant="outlined"
                endIcon={<StarIcon />}
                onClick={() => {
                  navigateToCopywriting(selectedArticle);
                  setDialogOpen(false);
                }}
              >
                Use in Copywriting
              </Button>
              <Button
                variant="outlined"
                endIcon={<OpenInNewIcon />}
                onClick={() => window.open(selectedArticle.link, "_blank")}
              >
                View Original
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

// Image slider component for displaying multiple images
const ImageSlider: React.FC<{ images: Image[]; title: string }> = ({
  images,
  title,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep(
      (prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps
    );
  };

  return (
    <Box sx={{ position: "relative", width: "100%", height: "100%" }}>
      <CardMedia
        component="img"
        sx={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
        image={images[activeStep].url}
        alt={images[activeStep].title || title}
      />
      {maxSteps > 1 && (
        <>
          <IconButton
            size="small"
            onClick={handleBack}
            sx={{
              position: "absolute",
              left: 8,
              top: "50%",
              transform: "translateY(-50%)",
              bgcolor: "rgba(255, 255, 255, 0.7)",
              "&:hover": { bgcolor: "rgba(255, 255, 255, 0.9)" },
            }}
          >
            <KeyboardArrowLeft />
          </IconButton>
          <IconButton
            size="small"
            onClick={handleNext}
            sx={{
              position: "absolute",
              right: 8,
              top: "50%",
              transform: "translateY(-50%)",
              bgcolor: "rgba(255, 255, 255, 0.7)",
              "&:hover": { bgcolor: "rgba(255, 255, 255, 0.9)" },
            }}
          >
            <KeyboardArrowRight />
          </IconButton>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              bgcolor: "rgba(255, 255, 255, 0.5)",
              "& .MuiMobileStepper-dot": {
                bgcolor: "rgba(0, 0, 0, 0.3)",
              },
              "& .MuiMobileStepper-dotActive": {
                bgcolor: "rgba(0, 0, 0, 0.8)",
              },
            }}
            nextButton={<div />}
            backButton={<div />}
          />
        </>
      )}
    </Box>
  );
};
export default RSSPage;
